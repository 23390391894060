import { isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { Request } from 'express';
import { REQUEST } from '../../../../express.tokens';

@Component({
    selector: 'kb-route-not-found',
    templateUrl: './route-not-found.component.html',
    styleUrls: ['./route-not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class RouteNotFoundComponent implements OnInit {
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Optional() @Inject(REQUEST) private request: Request,
    ) {}

    ngOnInit() {
        if (isPlatformServer(this.platformId)) {
            if (this.request?.res) {
                this.request.res.status(404);
            }
        }
    }
}

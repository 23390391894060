import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { DataService } from '../core/providers/data/data.service';
import { ServerResponseService } from '../core/providers/server-response/server-response.service';
import { GetRedirectDocument } from '../common/gql/graphql';

import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RedirectGuard  {
    constructor(
        private dataService: DataService,
        private router: Router,
        private serverResponseService: ServerResponseService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const path = '/' + (route.url[0]?.path ?? '');
        return this.dataService.query(GetRedirectDocument, { path }, { ssr: true }).pipe(
            map((data) => data.redirectForPath),
            take(1),
            map((result) => {
                if (!result) {
                    return true;
                } else {
                    const redirectPath = result;
                    this.serverResponseService.redirect(redirectPath);
                    return this.router.parseUrl(redirectPath);
                }
            }),
        );
    }
}
